import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'

import RenderBlock from '../utils/render-block'

class LandingTemplate extends Component {
  render() {
    let page = this.props.data.wordpressWpLanding
    let metaDescription = page.yoast_meta.find(x => x.name === 'description') ? page.yoast_meta.find(x => x.name === 'description').content : '';
    return (
      <>
        <section>
          <SEO title={he.decode(page.yoast_title)} bodyClass={`landing-template`} description={metaDescription} />
          { page.acf.landing_blocks_landing && page.acf.landing_blocks_landing.map((el, i) => {
            return RenderBlock(el.__typename, el, i)
          }) }
        </section>
      </>
    )
  }
}

export const LandingQuery = graphql`
  query ($id: String!) {
    wordpressWpLanding(id: {eq: $id}) {
      title
      slug
      yoast_title
      yoast_meta {  
       name
       content
       property
      }
      acf {
        landing_blocks_landing {
          __typename
          ... on WordPressAcf_content_video_image {
            title
            content
            background_image {
              localFile {
                childImageSharp {
                  fixed(width: 2600) {
                    src
                  }
                }
              }
            }
            video_content
            video
            video_image {
              localFile {
                childImageSharp {
                  fixed(width: 500) {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_video_image {
            background_image {
              localFile {
                childImageSharp {
                  fixed(width: 2600) {
                    src
                  }
                }
              }
            }
            video_content
            video
            video_image {
              localFile {
                childImageSharp {
                  fixed(width: 500) {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_our_services {
            title
            subtitle
            content
          }
          ... on WordPressAcf_basic_page {
            title
            content
          }
          ... on WordPressAcf_page_header {
            title
            content
          }
          ... on WordPressAcf_three_column_content {
            column {
              title
              content
            }
            text_color
            background_color
          }
          ... on WordPressAcf_letters {
            f_image {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
            s_image {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
            c_image {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_image_content {
            rows {
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 1400) {
                      src
                    }
                  }
                }
              }
              title
              content
            }
          }
          ... on WordPressAcf_slim_contact {
            button_text
            button_link
          }
          ... on WordPressAcf_team {
            team {
              title: post_title
              acf {
                profile_picture {
                  localFile {
                    childImageSharp {
                      fixed(width: 800) {
                        src
                      }
                    }
                  }
                }
                position
                linkedin
                short_bio
                full_bio
              }
            }
          }
          ... on WordPressAcf_landing_form_banner {
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
            subheading
            form_title
          }
          ... on WordPressAcf_landing_content_video {
            title
            content
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            video
          }
          ... on WordPressAcf_alternate_landing_form_banner {
            logo{
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
            subheading
            form_title
            name
            contact
            email
            enquiry
            buttontext
          }
          ... on WordPressAcf_three_column_image {
            text_color
            background_color
            column {
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              title
              content
            }
          }
          ... on WordPressAcf_landing_contact {
            title
            person {
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              name
              role
              phone
            }
          }
          ... on WordPressAcf_drone_info_images {
            subtitle
            heading
            content
            text_color
            background_color
            column {
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              title
              content
            }
          }
          ... on WordPressAcf_two_column_repeater {
            items {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_full_width_image {
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_landing_contact {
            title
            person {
              name
              role
              phone
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
            name
            email
            contact
            enquiry
            buttontext
          }
          ... on WordPressAcf_heading_content_landing {
            title
            content
          }
          ... on WordPressAcf_package_two_column {
            subtitle
            title
            content
            packages {
              title
              price
              content
              features {
                text
              }
              button {
                text
                link
              }
              recommended
            }
          }
          ... on WordPressAcf_package_three_column {
            subtitle
            title
            content
            packages {
              title
              price
              content
              features {
                text
              }
              button {
                text
                link
              }
              recommended
            }
          }
          ... on WordPressAcf_two_column_image_content {
            title
            content
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_embed_content {
            background_color
            html
          }
        }
      }
    }
  }
`

export default LandingTemplate
